export const errorCodeToMessageMap = {
    ALREADY_EXIST: 'Already exists',
    PROBLEM_WITH_INSERT_IN_DB: 'Problem with inserting in the database',
    ERROR: 'An error occurred',
    NOT_FOUND: 'Not found',
    PROBLEM_WITH_REMOVING: 'Problem with removing',
    DIFFERENT_LOCATION: 'Different location',
    BAD_REQUEST: 'Bad request',
    NULL_NOT_ALLOWED: 'Null value is not allowed',
    INCONSISTENT_DATA_PROVIDED: 'Inconsistent data provided',
    NOT_A_CAMERA_ELEMENT: 'Not a camera element',
    WRONG_COMPANY_CARD_NUMBER_FORMAT: 'Wrong company card number format',
    INVALID_DATE_OF_BIRTH: 'Invalid birth date',
    FULL_NAME_MISSING: 'Full name is missing',
    DUPLICATE_NAME: 'Duplicate name',
    EMAIL_ALREADY_EXIST: 'Email already exist',
    DUPLICATE_EMAIL: 'Another user with same email already exist.',
    PLANNED_TIME_MUST_NOT_BE_IN_PAST: 'Planned time must be in the future',
    BOTH_CDL_AND_DL_ALREADY_EXIST: 'The provided CDL and DL number already exist in the system.',
    CDL_ALREADY_EXIST: 'The provided CDL number already exists in the system.',
    DL_ALREADY_EXIST: 'The provided DL number already exists in the system.',
    USER_NOT_FOUND: 'User not found.',
    USER_EMAIL_EMPTY: 'User email is missing.',
    DUPLICATE_ORGANIZATION_NAME: 'The provided organization name already exists in the system.',
    LOADING_ERROR_MESSAGE: 'An error occurred while loading. Please reload the page and try again.',
    YARD_CHECK_NAME_ALREADY_EXIST: 'Yard Check Name already exist',
};

export const formatErrorCode = (errorCode) => {
    return errorCode.replace(/_/g, ' ').toLowerCase();
};

export const getFormattedErrorMessage = (errorMsg) => {
    if (!errorMsg) return 'An unknown error occurred';

    const errorCodeMatch = errorMsg.match(/\[([^\]]{1,100})\]/);
    if (!errorCodeMatch) return 'An unknown error occurred';

    const errorCode = errorCodeMatch[1];
    return errorCodeToMessageMap[errorCode] || formatErrorCode(errorCode);
};

export const getFormattedErrorMessageFromErrorObject = (error) => {
    if (error?.response?.status === 500) {
        return getFormattedErrorMessage('[LOADING_ERROR_MESSAGE]');
    }

    const msg = error?.response?.data?.error || error?.message;
    return getFormattedErrorMessage(msg);
};
