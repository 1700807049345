import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Box, Button, Divider, Link, List, ListItemText, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import styles from 'features/DefaultHelp.module.css';
import { useState } from 'react';

const HelpDialogSummary = () => {
    const instructions = [
        {
            key: 'editName',
            title: 'Edit Yard Check Name',
        },
        {
            key: 'switchItemList',
            title: 'Switch Item List',
        },
    ];

    const [selectedInstruction, setSelectedInstruction] = useState(instructions[0].key);

    const renderOverviewSection = () => (
        <>
            <h4>OVERVIEW</h4>
            <Typography>At this stage, all changes are made and users can only view the details of Yard Check.</Typography>
            <List className={styles['unordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>
                        <b>Matched:</b> List of Vehicles or equipment exist in both the physical Location and the digital Inventory.
                    </span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>
                        <b>New:</b> List of Vehicles or equipment are present in the physical Location but are not recorded in the digital Inventory.
                    </span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>
                        <b>Mismatched:</b> List of Vehicles or equipment are recorded in the digital Inventory but are not present in the physical
                        Location.
                    </span>
                </ListItemText>
            </List>
        </>
    );

    const renderControlsSection = () => (
        <>
            <h4>CONTROLS</h4>
            <Table className={styles['controls-table']}>
                <TableHead>
                    <TableRow>
                        <TableCell>Actions</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Yard Check Information</TableCell>
                        <TableCell>Display basic information of Yard Check.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Yard Check Name Edit</span>
                            <span>
                                <BorderColorIcon size="small" className={styles['inlined-icon']} />
                            </span>
                        </TableCell>
                        <TableCell>Allows users to edit Yard Check Name.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Status Tab</TableCell>
                        <TableCell>
                            <Typography>[Image of a status tab]</Typography>
                            Allows switching between Item list in viewing Yard Check.
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Action</TableCell>
                        <TableCell>
                            <Typography>[Image of an action dropdown]</Typography>
                            Display the selected remedial action for each Item. These are not editable.
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Yard Check Summary</TableCell>
                        <TableCell>
                            Summary numbers of Yard Check, including:
                            <List className={styles['unordered-list']}>
                                <ListItemText className={styles['list-item']}>
                                    <b>Inventory:</b> Total number of Assets in <b>Current Inventory</b>.
                                </ListItemText>
                                <ListItemText className={styles['list-item']}>
                                    <b>Yard Check:</b> Total number of Items in Yard Check.
                                </ListItemText>
                                <ListItemText className={styles['list-item']}>
                                    <b>Difference:</b> Total number of unchecked Assets in <b>Current Inventory</b>.
                                </ListItemText>
                            </List>
                            By expanding this summary section, users can see detail summary of each Asset Type compare with <b>Current Inventory</b>.
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );

    const renderInstructionsSection = () => (
        <>
            <h4>INTERACTIONS</h4>
            <Box display="flex" flexDirection="row" boxSizing="border-box" height="24rem" width="100%">
                <Box className={styles['instruction-title-container']}>
                    <Stack spacing={2}>
                        {instructions.map((instruction) => (
                            <Button
                                key={instruction.key}
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                    setSelectedInstruction(instruction.key);
                                }}
                                className={`${styles['instruction-button']} ${
                                    selectedInstruction === instruction.key ? styles['instruction-button--selected'] : ''
                                }`}
                                disableRipple
                            >
                                {instruction.title}
                                <ArrowForwardIosRoundedIcon className={styles['arrow-right']} />
                            </Button>
                        ))}
                    </Stack>
                </Box>
                <Box className={styles['instruction-content-container']}>{renderInstructionContent(selectedInstruction)}</Box>
            </Box>
        </>
    );

    const renderInstructionContent = (key) => {
        switch (key) {
            case 'editName':
                return (
                    <Box className={styles['instruction-child-container']}>
                        <p className={styles['instruction-header']}>Edit Yard Check Name</p>
                        <List className={styles['unordered-list']}>
                            <ListItemText>
                                1. Click on edit icon (<BorderColorIcon size="small" className={styles['inlined-icon']} />) beside Yard Check Name to
                                enable inline editor.
                            </ListItemText>
                            <ListItemText>2. Enter new Yard Check Name.</ListItemText>
                            <ListItemText>3. Press Enter to save change.</ListItemText>
                        </List>
                        <p className={styles['instruction-header']}>
                            {' '}
                            <i>* Only privileged users can edit Yard Check details.</i>
                        </p>
                    </Box>
                );
            case 'switchItemList':
                return (
                    <Box className={styles['instruction-child-container']}>
                        <p className={styles['instruction-header']}>Switch Item List</p>
                        <List className={styles['unordered-list']}>
                            <ListItemText>1. Choose any Tab to switch between list of Items and its remedial action.</ListItemText>
                            <ListItemText>
                                2. Tabs include:
                                <List>
                                    <ListItemText>
                                        <b>All:</b> All items in Checking List regardless of their statuses.
                                    </ListItemText>
                                    <ListItemText>
                                        <b>New:</b> Items in Checking List with status as <b>New</b>.
                                    </ListItemText>
                                    <ListItemText>
                                        <b>Mismatched:</b> Items in Checking List with status as <b>Mismatched</b>.
                                    </ListItemText>
                                    <ListItemText>
                                        <b>Matched:</b> Items in Checking List with status as <b>Matched</b>.
                                    </ListItemText>
                                </List>
                            </ListItemText>
                        </List>
                    </Box>
                );
            default:
                return null;
        }
    };

    const renderDivider = () => <Divider className={styles['divider']} />;

    const renderFooter = () => (
        <Stack className={styles['footer']}>
            <span>
                {`For further support, email us at `}
                <Link href="mailto:cs@birdseye.ca" underline="none">
                    cs@birdseye.ca
                </Link>
                {'.'}
            </span>
        </Stack>
    );

    return (
        <Stack className={styles['help-dialog-container']}>
            <Box className={styles['help-dialog-content-container']}>
                {renderOverviewSection()}
                {renderDivider()}
                {renderControlsSection()}
                {renderDivider()}
                {renderInstructionsSection()}
            </Box>
            {renderFooter()}
        </Stack>
    );
};

export default HelpDialogSummary;
