import AppLayout from 'components/layout/AppLayout.jsx';
import ProtectedRoute from 'components/protectedRoute/ProtectedRoute.jsx';
import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { ROLES } from 'utils/constants.js';

import GlobalLocationSelector from 'components/globalLocationSelector/GlobalLocationSelector.jsx';
import AccessPointRecordsHelpDialog from 'features/accessPointRecords/help/HelpDialog';
import AppointmentListHelpDialog from 'features/appointments/help/HelpDialog';
import InventoryHelpDialog from 'features/inventory/help/HelpDialog';
import OrganizationListHelpDialog from 'features/organizationList/help/HelpDialog';
import PeopleAuthorizedListHelpDialog from 'features/peopleAuthorizedList/help/HelpDialog';
import AuthorizedOrganizationAndLocationListHelpDialog from 'features/userLocations/help/HelpDialog';
import YardCheckHelpDialog from 'features/yardCheck/help/HelpDialog';
import YardCheckHelpDialogDetail from 'features/yardCheck/help/HelpDialogDetail';
import YardCheckHelpDialogSummary from 'features/yardCheck/help/HelpDialogSummary';

/* eslint-disable react-refresh/only-export-components */
const Redirect = React.lazy(() => import('pages/Redirect/Redirect.jsx'));
const NoPage = React.lazy(() => import('pages/NoPage.jsx'));
const ControlCentar = React.lazy(() => import('pages/ControlCentar/controlCentar.jsx'));
const CameraInfo = React.lazy(() => import('pages/CameraInfo/CameraInfo.jsx'));
const MonitoringObject = React.lazy(() => import('pages/MonitoringObjectPage/MonitoringObject.jsx'));
const EmployeePage = React.lazy(() => import('pages/EmployeePage/EmployeePage'));
const PeopleAuthorizedListPage = React.lazy(() => import('pages/PeopleAuthorizedListPage/PeopleAuthorizedListPage'));
const MonitoringElements = React.lazy(() => import('pages/MonitoringElementsPage/MonitoringElements'));
const CompanyAuthorizedListPage = React.lazy(() => import('pages/CompanyAuthorizedListPage/CompanyAuthorizedListPage'));
const OrganizationListPage = React.lazy(() => import('pages/OrganizationListPage/OrganizationListPage'));
const DivisionListPage = React.lazy(() => import('pages/DivisionList/DivisionList'));
const LocationListPage = React.lazy(() => import('pages/LocationListPage/LocationListPage'));
const ListDynamicFormsPage = React.lazy(() => import('pages/DynamicForms/ListDynamicFormsPage/ListDynamicFormsPage'));
const ListSectionTemplatesPage = React.lazy(() => import('pages/DynamicForms/ListSectionTemplatesPage/ListSectionTemplatesPage'));
const ListFieldsTemplatesPage = React.lazy(() => import('pages/DynamicForms/ListFieldsTemplatesPage/ListFieldsTemplatesPage'));
const HomePage = React.lazy(() => import('pages/HomePage'));
const RegionsOfInterest = React.lazy(() => import('pages/RegionsOfInterest/RegionsOfInterest'));
const AccessPointRecords = React.lazy(() => import('pages/AccessPointRecords/AccessPointRecords'));
const UserLocationsPage = React.lazy(() => import('pages/UserLocationsPage/UserLocationsPage'));
const SSOConfigurationPage = React.lazy(() => import('pages/SSOConfigurationPage/SSOConfigurationPage'));
const AudioConfigurationPage = React.lazy(() => import('pages/AudioConfiguration/AudioConfiguration'));
const AppointmentPage = React.lazy(() => import('pages/AppointmentPage/AppointmentPage'));
const ClientPersonnelListPage = React.lazy(() => import('pages/ClientPersonnel/ClientPersonnel'));
const InventoryPage = React.lazy(() => import('pages/InventoryPage/InventoryPage'));
const RequestTickets = React.lazy(() => import('pages/RequestTickets/RequestTickets'));
const RelaysPage = React.lazy(() => import('pages/ReleysPage/Relays'));
const YardCheckPage = React.lazy(() => import('pages/YardCheckPage/YardCheckPage'));
const YardCheckDetailPage = React.lazy(() => import('pages/YardCheckPage/YardCheckDetailPage'));
const YardCheckSummaryPage = React.lazy(() => import('pages/YardCheckPage/YardCheckSummaryPage'));

export const createAppRoute = (user, featureFlag) => {
    const hasUserRole = (role) => {
        if (Array.isArray(role)) {
            for (let userRole of role) if (isAllowed(userRole)) return true;
            return false;
        }
        return isAllowed(role);
        function isAllowed(role) {
            return user?.roles?.includes(role) || user?.roles?.includes(ROLES.ADMIN) || user?.roles?.includes(ROLES.USER);
        }
    };
    return createBrowserRouter(
        createRoutesFromElements(
            <Route element={<AppLayout />}>
                <Route path="/redirect" element={<Redirect user={user} />} />
                <Route element={<ProtectedRoute isAllowed={!!user} redirectPath="/redirect" />}>
                    <Route path="/" element={<HomePage />} />
                    <Route element={<ProtectedRoute isAllowed={hasUserRole(ROLES.HR)} />}>
                        <Route path="/employees" element={<EmployeePage />} />
                    </Route>
                    <Route
                        element={
                            <ProtectedRoute
                                isAllowed={hasUserRole([ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER])}
                            />
                        }
                    >
                        <Route
                            path="/people-authorized-list"
                            element={<PeopleAuthorizedListPage />}
                            handle={{
                                topNavMiddleAdornment: <GlobalLocationSelector />,
                                helpDialogComponent: <PeopleAuthorizedListHelpDialog />,
                                pageTitle: 'Authorized Lists - People',
                            }}
                        />
                        <Route
                            path="/company-authorized-list"
                            element={<CompanyAuthorizedListPage />}
                            handle={{
                                topNavMiddleAdornment: <GlobalLocationSelector />,
                                helpDialogComponent: <OrganizationListHelpDialog />,
                                pageTitle: 'Authorized Lists - Organizations',
                            }}
                        />
                    </Route>
                    <Route
                        isAllowed={hasUserRole(ROLES.ORGANIZATION_ADMIN)}
                        path="/sso-configuration"
                        element={<SSOConfigurationPage />}
                        handle={{
                            pageTitle: 'SSO Configuration',
                        }}
                    />
                    <Route element={<ProtectedRoute isAllowed={hasUserRole([ROLES.HQ_SUPPORT, ROLES.SALES, ROLES.TEAM_LEADER])} />}>
                        <Route path="/organization-list" element={<OrganizationListPage />} />
                    </Route>
                    <Route element={<ProtectedRoute isAllowed={hasUserRole([ROLES.HQ_SUPPORT, ROLES.SALES])} />}>
                        <Route path="/location-list" element={<LocationListPage />} />
                        <Route path="/divison-list" element={<DivisionListPage />} />
                    </Route>

                    <Route element={<ProtectedRoute isAllowed={hasUserRole([ROLES.ADMIN, ROLES.TEAM_LEADER])} />}>
                        <Route path="/client-personnel" element={<ClientPersonnelListPage />} />
                    </Route>

                    <Route element={<ProtectedRoute isAllowed={hasUserRole(ROLES.TECH)} />}>
                        <Route path="/monitoring-elements/audio" element={<AudioConfigurationPage />} />
                        <Route path="/monitoring-elements" element={<MonitoringElements />} />
                        <Route path="/monitoring-elements/relay" element={<RelaysPage />} />
                        <Route path="/control-center" element={<ControlCentar />} />
                        <Route path="/camera-tron" element={<CameraInfo />} />
                        <Route path="/monitoring-object" element={<MonitoringObject />} />
                    </Route>
                    <Route
                        element={<ProtectedRoute isAllowed={hasUserRole([ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER])} />}
                    >
                        <Route
                            path="/access-point-record-list"
                            element={<AccessPointRecords />}
                            handle={{
                                topNavMiddleAdornment: <GlobalLocationSelector />,
                                helpDialogComponent: <AccessPointRecordsHelpDialog />,
                                pageTitle: 'Access Point Records',
                            }}
                        />
                    </Route>
                    <Route element={<ProtectedRoute isAllowed={hasUserRole(ROLES.ADMIN)} />}>
                        <Route path="/regions-of-interest" element={<RegionsOfInterest user={user} />} />
                        <Route
                            path="/authorized-organization-locations"
                            element={<UserLocationsPage />}
                            handle={{
                                helpDialogComponent: <AuthorizedOrganizationAndLocationListHelpDialog />,
                                pageTitle: 'Authorized Organizations & Locations',
                            }}
                        />
                        {featureFlag?.isDynamicFormsEnabled && (
                            <>
                                <Route path="/dynamic-form-list" element={<ListDynamicFormsPage />} />
                                <Route path="/section-template-list" element={<ListSectionTemplatesPage />} />
                                <Route path="/fields-template-list" element={<ListFieldsTemplatesPage />} />
                            </>
                        )}
                    </Route>
                    <Route
                        element={
                            <ProtectedRoute
                                isAllowed={hasUserRole([
                                    ROLES.AGENT,
                                    ROLES.FACILITY_MANAGER,
                                    ROLES.ORGANIZATION_ADMIN,
                                    ROLES.ADMIN,
                                    ROLES.TEAM_LEADER,
                                ])}
                            />
                        }
                    >
                        <Route
                            path="/appointment-list"
                            element={<AppointmentPage />}
                            handle={{
                                topNavMiddleAdornment: <GlobalLocationSelector />,
                                helpDialogComponent: <AppointmentListHelpDialog />,
                                pageTitle: 'Appointments',
                            }}
                        />
                    </Route>
                    <Route
                        element={
                            <ProtectedRoute isAllowed={hasUserRole([ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.ADMIN])} />
                        }
                    >
                        <Route
                            path="/inventory-list"
                            element={<InventoryPage />}
                            handle={{
                                topNavMiddleAdornment: <GlobalLocationSelector />,
                                helpDialogComponent: <InventoryHelpDialog />,
                                pageTitle: 'Inventory - List',
                            }}
                        />
                    </Route>
                    <Route
                        element={
                            <ProtectedRoute
                                isAllowed={hasUserRole(ROLES.FACILITY_MANAGER) || hasUserRole(ROLES.ORGANIZATION_ADMIN || hasUserRole(ROLES.ADMIN))}
                            />
                        }
                    >
                        <Route
                            path="/yard-check-list"
                            element={<YardCheckPage />}
                            handle={{
                                topNavMiddleAdornment: <GlobalLocationSelector />,
                                helpDialogComponent: <YardCheckHelpDialog />,
                                pageTitle: 'Yard Check List',
                            }}
                        />
                        <Route
                            path="/yard-check-detail/:id"
                            element={<YardCheckDetailPage />}
                            handle={{
                                helpDialogComponent: <YardCheckHelpDialogDetail />,
                                pageTitle: 'Yard Check (Open & Checking)',
                            }}
                        />
                        <Route
                            path="/yard-check-summary/:id"
                            element={<YardCheckSummaryPage />}
                            handle={{
                                helpDialogComponent: <YardCheckHelpDialogSummary />,
                                pageTitle: 'Yard Check (Finished)',
                            }}
                        />
                    </Route>

                    <Route
                        path="/request-tickets"
                        element={<RequestTickets />}
                        handle={{
                            topNavMiddleAdornment: <GlobalLocationSelector />,
                            pageTitle: 'Request Tickets',
                        }}
                    />
                </Route>
                <Route path="*" element={<NoPage />} />
            </Route>
        )
    );
};
