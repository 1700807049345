import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';

// TODO: Add component prop-types. Disabling the eslint rule for now.
/* eslint-disable react/prop-types */
const NotificationDismissButton = function ({ notificationId }) {
    const { closeSnackbar } = useSnackbar();
    return (
        <Button color="warning" onClick={() => closeSnackbar(notificationId)}>
            Dismiss
        </Button>
    );
};

//TODO move action to new file
// eslint-disable-next-line
export const action = (key) => <NotificationDismissButton notificationId={key} />;

export default NotificationDismissButton;
